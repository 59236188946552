import Vue from 'vue'
import App from './App.vue'
import TermsConditions from './TermsConditions.vue'
import PrivacyPolicy from './PrivacyPolicy.vue'
const NotFound = { template: '<p>Page not found</p>' }

const routes = {
  '/': App,
  '/terms-conditions': TermsConditions,
  '/privacy-policy': PrivacyPolicy
}

Vue.config.productionTip = false

new Vue({
  data: {
    currentRoute: window.location.pathname
  },
  computed: {
    ViewComponent () {
      return routes[this.currentRoute] || NotFound
    }
  },
  render (h) { return h(this.ViewComponent) }
}).$mount('#app')
