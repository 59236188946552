<template>
    <div id="sub-page" class="section">
        <div class="section-container h-screen">
            <div id="header-nav" class="header-nav w-full clear-both overflow-hidden inline-flex float-left relative sm:px-8 mb-8">
                <img class="header-logo" src="./assets/logo_header_2focus.svg" alt="2 Focus Logo">
                <nav class="p-3 w-full m-4">
                    <ol class="list-reset flex">
                        <li><a href="/" class="font-bold">Home</a></li>
                        <li><span class="mx-2">/</span></li>
                        <li>Privacy Policy</li>
                    </ol>
                </nav>
            </div>
            <div class="textarea mb-10" v-html="content"></div>
            <a class="button px-4 py-2" href="/">Go back home</a>
        </div>
    </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  data () {
    return {
      base_url: 'https://app.2focus.com.au/api/v1',
      resource_url: '/settings/PRIVACY_POLICY',
      api_key: 'QpekDGU+1vb7bhX1CeOtdBEgZtzNaHE0l5x88D7yLK4=',
      content: 'Loading..'
    }
  },
  methods: {
    getContent: function () {
      const url = this.base_url + this.resource_url
      const request = new Request(url, {
        method: 'GET',
        headers: new Headers({
          'x-api-key': this.api_key
        })
      })

      fetch(request)
        .then(resp => resp.json())
        .then(data => {
          if (data.result) {
            this.content = data.payload.setting_value
          }
        })
        .catch(function () {
          console.error('Can’t access ' + url + ' response. Blocked by browser?!')
        })
    }
  },
  created: function () {
    this.getContent()
  }
}
</script>

<style lang="scss">
@import './styles/style.scss';
</style>
