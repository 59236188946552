<template>
  <div id="app" class="overflow-hidden">
    <!-- Header Section Start -->
    <header id="header" class="section">
      <div id="header-container" class="section-container min-h-screen my-8">
        <div id="header-nav" class="header-nav w-full clear-both overflow-hidden inline-flex float-left relative sm:px-8">
          <img class="header-logo" src="./assets/logo_header_2focus.svg" alt="2 Focus Logo">

          <ul class="header-nav-group inline-flex float-left">
            <li class="header-nav-item ml-0"><a href="#about">About</a></li>
            <li class="header-nav-item ml-10"><a href="#features-heading">Features</a></li>
            <li class="header-nav-item ml-10"><a href="#screens">Screens</a></li>
            <li class="header-nav-item ml-10"><a href="#contact-us">Contact Us</a></li>
          </ul>

          <!-- Start Header Social Media Section -->
          <div class="header-social-links absolute right-0 float-left inline-flex">
            <a href="https://www.facebook.com/2focusgamingapp" class="social-item header-social-link-item ml-0" target="_blank"><img src="./assets/ic_header_fb.svg" alt="Facebook link"></a>
            <a href="https://www.instagram.com/2focusapp" class="social-item header-social-link-item ml-6" target="_blank"><img src="./assets/ic_header_insta.svg" alt="Instagram link"></a>
            <a href="https://www.youtube.com/channel/UCIrRZFxq0d9F_ATY2Twsddw" class="social-item header-social-link-item ml-6" target="_blank"><img src="./assets/ic_header_youtube.svg" alt="Youtube link"></a>
            <a href="https://www.tiktok.com/@2focusapp?lang=en" class="social-item header-social-link-item ml-6" target="_blank"><img src="./assets/ic_header_tiktok.svg" alt="Facebook link"></a>
            <!-- Collapsible Mobile Menu -->
            <a href="#" class="mobile-menu header-social-link-item ml-6 pr-4" @click="showCollapsibleMenu"><img src="./assets/ic_hamburger_menu.svg" alt="Menu"></a>
          </div>
          <!-- End Header Social Media Section -->
        </div>

        <div id="collapsible" ref="collapsible" class="block overflow-hidden clear-both w-full float-left p-8 text-center font-bold tracking-widest text-lg" :class="{ hidden: isHidden }">
          <li class="header-nav-item ml-0 list-none leading-10"><a href="#about">About</a></li>
          <li class="header-nav-item ml-0 list-none leading-10"><a href="#features-heading">Features</a></li>
          <li class="header-nav-item ml-0 list-none leading-10"><a href="#screens">Screens</a></li>
          <li class="header-nav-item ml-0 list-none leading-10"><a href="#contact-us">Contact Us</a></li>
        </div>

        <div id="header-content" class="section-content-flex">
          <div class="header-content-image-container-fake sm:clear-both sm:block md:block lg:hidden xl:hidden overflow-hidden">
            <img class="header-content-image object-contain object-center" src="./assets/header_iphone_mockup.svg" alt="2 Focus Intro">
          </div>

          <div class="header-content-text-container sm:clear-both p-4">
            <h1>Are you Ready to Win Real Money? 2Focus - The Revolution in Concentration Gaming...</h1>
            <h2 class="section-title">
              <span class="section-title-sub">It pays 2 be focused!</span>
            </h2>
            <p>
              2Focus is game of skill and memory.
            </p>
            <p>
              By spotting the difference in a number of mini movies you can win $250
            </p>
            <p>
              By simply opening an app, watching a clip and spotting some mistakes - You can increase or even replace your income within seconds!
            </p>

            <div class="header-content-text-mobile-store">
              <h4 class="mt-20 mb-8">Available On</h4>

              <!-- Start Store Links -->
              <!--<a href="https://play.google.com/store/apps/dev?id=au.com.twofocus" class="header-content-store-link ml-0" target="_blank"><img src="./assets/ic_google_play.svg" alt="Google Play Store"></a>-->
              <a href="https://apps.apple.com/au/app/2focus/id1517815936" class="header-content-store-link sm:ml-0 md:ml-5 lg:ml-0 xl:ml-5" target="_blank"><img src="./assets/ic_app_store.svg" alt="Apple App Store"></a>
              <!-- End Store Links -->
            </div>
          </div>

          <div class="header-content-image-container clear-right lg:flex-shrink-0 sm:hidden md:hidden lg:block xl:block overflow-hidden">
            <img class="header-content-image" src="./assets/header_iphone_mockup.svg" alt="2 Focus Intro">
          </div>
        </div>
      </div>
    </header>
    <!-- Header Section End -->

    <!-- About Section Start -->
    <div id="about" class="section">
      <div id="about-container" class="section-container my-8">
        <div id="about-content" class="section-content-flex">
          <div class="section-image-container-fake sm:clear-both sm:block md:block lg:hidden xl:hidden overflow-hidden">
            <img class="section-image object-contain object-center" src="./assets/img_about_2focus.svg" alt="About 2 Focus">
          </div>

          <div class="section-text-container sm:clear-both p-4">
            <h2 class="section-title">
              <span class="section-title-main">About</span>
              <span class="section-title-sub">2Focus</span>
            </h2>
            <p>
              2Focus is a mobile App that helps you improve your concentration and win $250 instant cash while you do it.
            </p>
            <p>
              You can download the 2Focus Application on your iOS device.The 2Focus App is completely free, with bonus upgrade features inside the app.
            </p>
            <p>
              Having installed the 2Focus App, you can watch a series of short videos.  Each video contains 12 mistakes. The first to guess all 12 mistakes right, wins $250.
            </p>
            <p>
              As soon as there is a winner for one video, it will automatically expire and a new video will be uploaded within 7 days of the expiry of that video.
            </p>
            <p>
              App users can access upgrade features within the 2Focus App that will reveal a number of clues as well.
            </p>
          </div>

          <div class="section-image-container clear-right lg:flex-shrink-0 sm:hidden md:hidden lg:block xl:block overflow-hidden">
            <img class="section-image" src="./assets/img_about_2focus.svg" alt="About 2 Focus">
          </div>
        </div>
      </div>
    </div>
    <!-- About Section End -->

    <!-- Features Section Start -->
    <div id="features-heading" class="section">
      <div id="features-heading-container" class="section-container my-20">
        <div id="features-heading-content" class="section-content p-4">
          <h2 class="section-title">
            <span class="section-title-main">2Focus</span>
            <span class="section-title-sub">Features</span>
          </h2>
        </div>
      </div>
    </div>

    <div id="features-main" class="section">
      <div id="features-main-container" class="section-container">
        <div id="features-main-content" class="section-content-flex">
          <div class="clear-left sm:clear-both lg:flex-shrink-0 overflow-hidden">
            <img class="section-image" src="./assets/img_iphone_mockup_features_section.svg" alt="2 Focus Features">
          </div>

          <div class="section-text-container sm:clear-both p-4">
            <ol class="features-list">
              <li>Gaming at its best!
                <span>Gone are the days of wasting time collecting pointless stars in pointless games. 2Focus is the new innovation in gaming. Test your focus and earn real cash while you are at it.</span>
              </li>

              <li>Spot and earn!
                <span>Think you have an eye for details. We will pay you $250 if you do. Yes, it’s that simple, watch a video, spot the difference and win. Let’s do it!</span>
              </li>

              <li>Improve your focus!
                <span>When we say ‘New Innovation in Gaming’ we mean you have fun, earn money, and yes, you improve your focus and concentration as well.</span>
              </li>

              <li>Completely Free!
                <span>You have nothing to lose, have some fun and try out the game. If you like it, then keep going. There is no downside to this. Let the games begin!</span>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <!-- Features Section End -->

    <!-- Screens Section Start -->
    <div id="screens" class="section">
      <div id="screens-container" class="section-container my-8">
        <div id="screens-content" class="section-content">
          <div id="screens-heading" class="section-heading-container clear-both overflow-hidden w-full p-4">
            <h2 class="section-title">
              <span class="section-title-main">2Focus</span>
              <span class="section-title-sub">Screens</span>
            </h2>
          </div>
        </div>
      </div>

      <div id="screens-carousel-container" class="clear-both mx-auto w-full overflow-hidden flex flex-row">
        <div class="carousel-nav-button clear-left flex-grow mr-12">
          <img src="./assets/slider_arrow_left.svg" alt="<" class="carousel-btn right-0" id="carousel-prev-btn" @click="prevSlide">
        </div>
        <div class="carousel-container overflow-hidden w-3/5 float-left .relative">
          <swiper class="carousel" ref="carousel" :options="swiperOptions">
            <swiper-slide><div class="center-frame"></div><img src="./assets/slides/slide-3.png"></swiper-slide>
            <swiper-slide><div class="center-frame"></div><img src="./assets/slides/slide-6.png"></swiper-slide>
            <swiper-slide><div class="center-frame"></div><img src="./assets/slides/slide-1.png"></swiper-slide>
            <swiper-slide><div class="center-frame"></div><img src="./assets/slides/slide-4.png"></swiper-slide>
            <swiper-slide><div class="center-frame"></div><img src="./assets/slides/slide-5.png"></swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
        <div class="carousel-nav-button clear-right flex-grow ml-12">
          <img src="./assets/slider_arrow_right.svg" alt=">" class="carousel-btn left-0" id="carousel-prev-btn" @click="nextSlide">
        </div>
      </div>
    </div>
    <!-- Screens Section End -->

    <!-- Contact Us Section Start -->
    <div id="contact-us" class="section">
      <div id="contact-us-container" class="section-container my-8">
        <div id="contact-us-heading" class="section-heading-container p-4">
          <h2 class="section-title">
            <span class="section-title-main">2Focus</span>
            <span class="section-title-sub">Contact Us</span>
          </h2>
        </div>

        <div id="contact-us-content" class="section-content-flex p-4">
          <div class="contact-us-form-container">
            <p>Please fill out the form below, and we will be in touch with lightning speed, you can email us at <a href="mailto:support@2focus.com.au?subject=Inquiry">support@2focus.com.au</a></p>
            <form action="/contact-us" class="w-full overflow-hidden text-left mt-10 pr-8" @submit="sendMail">
              <div class="grid grid-cols-2 gap-8">
                <div>
                  <label class="block mb-2" for="name">Name*</label>
                  <input class="w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" placeholder="" v-model="contactForm.name">
                </div>

                <div>
                  <label class="block mb-2" for="email">Email*</label>
                  <input class="w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline" id="email" type="text" placeholder="" v-model="contactForm.email">
                </div>

                <div class="col-span-2">
                  <label class="block mb-2" for="message">Message</label>
                  <textarea class="w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline" id="message" rows="10" v-model="contactForm.message"></textarea>
                  <br /><br /><br />
                  <vue-recaptcha ref="recaptcha" sitekey="6Lfi4O0gAAAAADH5H_5XUzZNb8L7tYN6xs81WlHC"/>
                </div>

                <div class="col-span-1">
                  <button class="focus:outline-none focus:shadow-outline" type="submit">Submit</button>
                </div>
              </div>
            </form>
          </div>

          <div class="address-container overflow-hidden">
            <address>
              <!--<li>Address<span>Lorem ipsum dolor sit amet, feugiat invenire inimicus ei mea,</span></li>-->
              <li>Email<span>support@2focus.com.au</span></li>
            </address>
          </div>
        </div>
      </div>
    </div>
    <!-- Contact Us Section End -->

    <!-- Footer Start -->
    <footer class="section">
      <div class="footer-top-seperator"></div>
      <div id="footer-container" class="section-container">
        <div id="footer-content" class="section-content-flex mt-8 lg:flex-shrink-0">
          <div class="foofter-caption overflow-hidden">
            <img class="footer-logo" src="./assets/logo_footer_2focus.svg" alt="2 Focus Logo">
            <span class="sm:w-full mt-4">
              <p>2Focus is a mobile App that helps you improve your concentration and win instant cash while you do it.</p>
            </span>
          </div>

          <div class="footer-links flex-grow overflow-hidden">
            <div class="grid grid-flow-col grid-cols-2 grid-rows-3 mt-10 gap-4">
              <div class="text-left px-20"><a href="#header">Home</a></div>
              <div class="text-left px-20"><a href="#about">About</a></div>
              <div class="text-left px-20"><a href="#features-heading">Features</a></div>
              <div class="text-left px-20"><a href="#screens">Screens</a></div>
              <div class="text-left px-20"><a href="#contact-us">Contact Us</a></div>
            </div>
          </div>

          <!-- Start Footer Social Media Section -->
          <div class="footer-social-links overflow-hidden inline-flex">
            <a href="https://www.facebook.com/2focusgamingapp" class="footer-social-link-item ml-0" target="_blank"><img src="./assets/ic_header_fb.svg" alt="Facebook link"></a>
            <a href="https://www.instagram.com/2focusapp" class="footer-social-link-item ml-4" target="_blank"><img src="./assets/ic_header_insta.svg" alt="Instagram link"></a>
            <a href="https://www.youtube.com/channel/UCIrRZFxq0d9F_ATY2Twsddw" class="footer-social-link-item ml-4" target="_blank"><img src="./assets/ic_header_youtube.svg" alt="Youtube link"></a>
            <a href="https://www.tiktok.com/@2focusapp?lang=en" class="social-item header-social-link-item ml-4" target="_blank"><img src="./assets/ic_header_tiktok.svg" alt="Facebook link"></a>
          </div>
          <!-- End Footer Social Media Section -->
        </div>

        <div class="footer-bottom-seperator section-content mt-6"></div>

        <div id="footer-copyright-content" class="section-content my-6 lg:flex-shrink-0">
          <div class="footer-terms-privacy flex-grow text-left">
            <span class="mr-2"><a href="/terms-conditions">Terms &amp; Conditions</a></span>|<span class="ml-2"><a href="/privacy-policy">Privacy policy</a></span>
          </div>
          <div class="footer-copyright flex-grow text-right">
            <span>Copyright &copy; 2022 2Focus | All Rights Reserved</span>
          </div>
        </div>
      </div>
    </footer>
    <!-- Footer End -->
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { VueRecaptcha } from 'vue-recaptcha'
export default {
  name: 'app',
  components: {
    Swiper,
    SwiperSlide,
    VueRecaptcha
  },
  data () {
    return {
      isHidden: true,
      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 50,
        centeredSlides: true,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      },
      base_url: 'https://app.2focus.com.au/api/v1',
      resource_url: '/contact-us',
      api_key: 'QpekDGU+1vb7bhX1CeOtdBEgZtzNaHE0l5x88D7yLK4=',
      contactForm: {
        name: '',
        email: '',
        message: ''
      }
    }
  },
  methods: {
    showCollapsibleMenu: function (e) {
      this.isHidden = !this.isHidden
    },
    prevSlide: function (e) {
      this.$refs.carousel.$swiper.slidePrev()
    },
    nextSlide: function (e) {
      this.$refs.carousel.$swiper.slideNext()
    },
    sendMail: function (e) {
      e.preventDefault()
      const url = this.base_url + this.resource_url
      const request = new Request(url, {
        method: 'POST',
        headers: new Headers({
          'x-api-key': this.api_key
        }),
        body: JSON.stringify(this.contactForm)
      })
      fetch(request)
        .then(resp => resp.json())
        .then(data => {
          if (data.result) {
            console.log('Mail sent!')
          } else {
            console.error(data)
          }
        })
        .catch(function () {
          console.error('Can’t access ' + url + ' response. Blocked by browser?!')
        })
      this.contactForm.name = ''
      this.contactForm.email = ''
      this.contactForm.message = ''
    },
    onEvent: function (e) {
      this.$refs.recaptcha.execute()
    }
  }
}
</script>

<style lang="scss">
  @import './styles/style.scss';
</style>
